import {
  Box,
  CssBaseline,
  Grid,
  ThemeProvider,
  createTheme,
  responsiveFontSizes
} from "@mui/material";
import React from "react";
import RegistrationCheckoutForm from "./NonprofitRegistrationCheckoutForm";

export default function NonprofitRegistrationPage({ page_title }) {
  // useEffect(() => {
  //   document.title = page_title;
  //   window.scrollTo(0, 0)
  // }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <main>
        {/* <PageHeader title="Nonprofit Registration" background={"#F1B9D0"} /> */}
        <Box sx={{ pb: 8, backgroundColor: "white" }}>
        
          <Grid
            style={{
              // paddingLeft: "7.14vw",
              // paddingRight: "7.14vw",
              paddingTop: "3.14vw",
            }}
          >
            <RegistrationCheckoutForm />
          </Grid>
        </Box>
      </main>
    </ThemeProvider>
  );
}

let theme = createTheme();

theme = responsiveFontSizes(theme);

theme.typography.topText = {
  fontFamily: "Changa",
  fontSize: "1.5rem",
  lineHeight: "1.5rem",
  textAlign: "center",
  flexGrow: 1,
  display: "flex",
  "@media screen and (max-width: 900px)": {
    fontSize: "1.3rem",
    lineHeight: "1.3rem",
  },
};

theme.typography.formStyle = {
  paddingLeft: "1.14vw",
  paddingRight: "1.14vw",
  paddingTop: "1.14vw",
  "@media screen and (max-width: 900px)": {
    paddingTop: "3.14vw",
    paddingBottom: "3.14vw",
  },
};

theme.typography.fieldTitle = {
  color: "#000",
  fontFamily: "Changa",
  fontSize: "18px",
  fontWeight: "900",
  lineHeight: "18px",
};
