import {
  FormGroup,
  Grid,
  Switch,
  ThemeProvider,
  Typography,
  createTheme,
  responsiveFontSizes
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import AsyncCreatableSelect from "react-select/async-creatable";
import { validate } from "validate.js";

import {
  CustomFormControlLabel,
  CustomMessageBox,
  CustomSendDonationButton,
  CustomTextField
} from "../../components/FormComponents/FormComponents";
import {
  EmployerIdentificationNumberPatternFormatCustom,
  PhoneNumberPatternFormatCustom,
} from "../../components/InputComponents/InputComponents";
import { social_issues } from "../CKSurvey/social_issues";
import { eventPurposeNonprofit } from "./data";
import { schema } from "./nonprofitRegistrationSchema";

const formValues = {
  message: "",
};

export default function RegistrationCheckoutForm() {
  const [useMyInfo, setuseMyInfo] = useState(false);

  const donationForm = useRef();
  const navigate = useNavigate();
  const [characterLimit] = useState(1000);
  const [connectedSocialIssues, setSocialIssues] = useState([]);
  const [event_reasons, setevent_reasons] = useState([]);
  const [repCount, setRepCount] = useState([0]);

  // const handleIncrease = (person) => {
  //   let temp = [...repCount];
  //   temp.push(person)
  //   setRepCount([person,...repCount])
  //   console.log(repCount,temp)

  // }

  // const handleDecrease = (person) => {
  //   // let tempArr =
  //   // // const index = tempArr.indexOf(person);
  //   // console.log(tempArr)

  //   // if (index > -1) { // only splice array when item is found
  //   //   console.log(repCount.splice(person, 1))
  //   console.log(repCount)
  //     setRepCount(repCount.splice(person, 1));
  //     console.log(repCount)// 2nd parameter means remove
  //   // }

  //   // setRepCount(tempArr)
  // }
  const storedValue = JSON.parse(
    sessionStorage.getItem("nonprofit_registration")
  );
  const [formState, setFormState] = useState(
    storedValue
      ? storedValue
      : {
          isValid: false,
          values: formValues,
          touched: {},
          errors: {},
        }
  );
  const toggleUseMyInfo = () => {
    !useMyInfo ===true ?
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        ["rep1_first_name"]:
        formState.values.registrant_first_name,
        ["rep1_last_name"]:
        formState.values.registrant_last_name,
        ["rep1_email_address"]:
        formState.values.registrant_email_address,
      },
      touched: {
        ...formState.touched,
        ["rep1_first_name"]:
        true,
        ["rep1_last_name"]:
        true,
        ["rep1_email_address"]:
        true,
      },
    }))
    :
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        ["rep1_first_name"]:
        "",
        ["rep1_last_name"]:
        "",
        ["rep1_email_address"]:
        "",
      },
      touched: {
        ...formState.touched,
        ["rep1_first_name"]:
        false,
        ["rep1_last_name"]:
        false,
        ["rep1_email_address"]:
        false,
      },
    }))
    ;
    setuseMyInfo(!useMyInfo);

  };

  useEffect(() => {
    const errors = validate(formState.values, schema, { fullMessages: false });
    // setRepCount([0]);
    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  useLayoutEffect(() => {}, [repCount]);

  const handleChange = (e) => {

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [e.target.name]:
          e.target.type === "checkbox" ? e.target.checked : e.target.value,
      },
      touched: {
        ...formState.touched,
        [e.target.name]: true,
      },
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    sessionStorage.setItem("nonprofit_registration", JSON.stringify(formState));

    navigate("/care/register/nonprofit_terms", {
      state: {
        registrant_first_name: formState.values.registrant_first_name,
        registrant_last_name: formState.values.registrant_last_name,
        registrant_email_address: formState.values.registrant_email_address,
        registrant_phone_number: formState.values.registrant_phone_number,
        org_name: formState.values.org_name,
        org_ein: formState.values.org_ein,
        org_email_address: formState.values.org_email_address,
        org_phone_number: formState.values.org_phone_number,
        headquarters: formState.values.headquarters,
        website: formState.values.website,
        mission: formState.values.mission,
        social_issues: formState.values.social_issues,
        event_reasons: formState.values.event_reasons,
        rep1_first_name: formState.values.rep1_first_name,
        rep1_last_name: formState.values.rep1_last_name,
        rep1_email_address: formState.values.rep1_email_address,
        rep1_role: formState.values.rep1_role,
        opt_in: formState.values.opt_in,
        send_materials: formState.values.send_materials,
        message: formState.values.message,
        program_title:"The Clarintel Care Fair",
        registration_amount: 50.0,
        // programTitle: formState.values.program.programTitle,
        // programDescription: formState.values.program.programDescription,
      },
    });
  };

  const isFormComplete = () => {
    return (
      Object.keys(formState?.values).length > 0 &&
      formState.values?.registrant_first_name?.length > 0 &&
      formState.values?.registrant_last_name?.length > 0 &&
      formState.values?.registrant_email_address?.length > 0 &&
      formState.values?.registrant_phone_number?.toString().length > 0 &&
      formState.values?.org_name?.length > 0 &&
      formState.values?.org_ein?.length > 0 &&
      formState.values?.org_email_address?.length > 0 &&
      formState.values?.org_phone_number?.toString().length > 0 &&
      formState.values?.headquarters?.length > 0 &&
      // formState.values?.website?.length > 0 &&
      formState.values.mission?.length > 0 &&
      // formState.values?.event_reasonss?.length > 0 &&
      formState.values?.rep1_first_name?.length > 0 &&
      formState.values?.rep1_last_name?.length > 0 &&
      formState.values?.rep1_email_address?.length > 0 &&
      formState.values?.rep1_role?.length > 0 
      // formState.isValid
    );
  };
  const handleSetValue = (value, setValue, id, type) => {
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [id]:
          type === "checkbubble"
            ? value
            : Object.values(value.map((i) => i.label)),
      },
      touched: {
        ...formState.touched,
        [id]: true,
      },
    }));
    setValue(value);
  };
  const CustomCreatableSelect = ({ data, question, answer, setAnwser }) => {
    const filterColors = (inputValue) => {
      return data.filter((i) =>
        i.label.toLowerCase().includes(inputValue.toLowerCase())
      );
    };

    const promiseOptions = (inputValue) =>
      new Promise((resolve) => {
        setTimeout(() => {
          resolve(filterColors(inputValue));
        }, 500);
      });

    return (
      <AsyncCreatableSelect
        isMulti
        value={answer}
        cacheOptions
        name={question}
        defaultOptions={data.slice(0, 100)}
        loadOptions={promiseOptions}
        onChange={(newValue) =>
          handleSetValue(newValue, setAnwser, question, "arraylist")
        }
        menuPortalTarget={document.body}
        styles={{
          container: (base) => ({
            justifyContent: "center",
            alignItems: "center",
            paddingTop: "10px",
          }),
          control: (base, state) => ({
            ...base,
            borderRadius: "30px",
            fontFamily: "Changa",
            minHeight: "70px",
            borderColor: state.isFocused ? "#62B6D0" : "lightgrey",
            boxShadow: state.isFocused ? "0 0 0 1px #62B6D0" : 0,
            "&:hover": { borderColor: "#62B6D0" },
          }),
          menu: (base) => ({
            ...base,
            marginTop: "10px",
            zIndex: 9999,
            fontFamily: "Changa",
            birderRadius: "20px",
          }),
          option: (base, state) => ({
            ...base,
            fontFamily: "Changa",
            backgroundColor: state.isFocused ? "#62B6D0" : "#FFF",
            "&:hover": { backgroundColor: "#62B6D0" },
          }),
          multiValue: (styles, { data }) => {
            // const color = ;
            return {
              ...styles,
              backgroundColor: "#62B6D0",
              borderRadius: "40px",
            };
          },
          multiValueLabel: (styles, { data }) => ({
            ...styles,
            color: "#FFF",
          }),
          multiValueRemove: (styles, { data }) => ({
            ...styles,
            color: "rgba(255,255,255,.7)",
            ":hover": {
              backgroundColor: "transparent",
              color: "white",
            },
          }),
        }}
        options={data}
      />
    );
  };
  const PinkSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: "rgb(98, 182, 208)",
      '&:hover': {
        backgroundColor: "rgba(98, 182, 208,.3)",
      },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: "rgb(98, 182, 208)",
    },
  }));
  
  // const label = { inputProps: { 'aria-label': 'Color switch demo' } };
  
  const getReps = () => {
    const data = [1];
    return data.map((person, i) => (
      <Grid container direction="row" key={i} sx={{ pb: "35px" }}>
        {/* First Name and Last Name */}
        <Grid container style={{ flexDirection: "row", alignItems: "center", width:"100%", justifyContent:"space-between" }}>
          <Typography
            variant="contactTitle"
            sx={{
              fontSize: {
                xs: "1.1rem",
                sm: "1.1rem",
                md: "1.2rem",
                lg: "1.5rem",
              },
              lineHeight: {
                xs: "1.3rem",
                sm: "1.3rem",
                md: "1.4rem",
                lg: "1.7rem",
              },
            }}
          >
            Representative {i + 1} {i === 0 ? "*" : null}
          </Typography>
          {i===0?
          
          // <PinkSwitch {...label} defaultChecked />
          <FormGroup>
      <CustomFormControlLabel
        control={<PinkSwitch  checked={useMyInfo} onChange={toggleUseMyInfo} />}
        label="I am attending the event."
      />
    </FormGroup>
          :null}
          {/* <CustomButton 
                        sx={{
                          borderRadius:"180px",
                            maxWidth: "100px", 
                            width:"100px",
                            height:"100px",
                            maxHeight: "100px", 
                            margin:0,
                            display:i>0?"block" :"none"
                        }} 
                        onClick={()=>handleDecrease(i)}
                        
                      >
                        <Typography
                    // variant="contactSubTitle"
                    sx={{ fontSize:"20px" }}
                  >
                    -
                  </Typography>
                      </CustomButton> */}
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={6} typography="formStyleLeft">
          <Typography variant="fieldTitle">
            First Name{i === 0 ? "*" : null}
          </Typography>
          <CustomTextField
            name={`rep${person.toString()}_first_name`}
            placeholder="First Name"
            value={
              formState?.values[`rep${person.toString()}_first_name`]
                ? formState?.values[`rep${person.toString()}_first_name`]
                : ""
            }
            variant="outlined"
            size="medium"
            id={`rep${person.toString()}_first_name`}
            fullWidth
            type="text"
            onChange={handleChange}
            helperText={
              hasError(`rep${person.toString()}_first_name`)
                ? formState.errors[`rep${person.toString()}_first_name`][0]
                : null
            }
            error={hasError(`rep${person.toString()}_first_name`)}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} typography="formStyleRight">
          <Typography variant="fieldTitle">
            Last Name{i === 0 ? "*" : null}
          </Typography>
          <CustomTextField
            name={`rep${person.toString()}_last_name`}
            value={
              formState?.values[`rep${person.toString()}_last_name`]
                ? formState?.values[`rep${person.toString()}_last_name`]
                : ""
            }
            placeholder="Last Name"
            variant="outlined"
            size="medium"
            id={`rep${person.toString()}_last_name`}
            fullWidth
            type="text"
            onChange={handleChange}
            helperText={
              hasError(`rep${person.toString()}_last_name`)
                ? formState.errors[`rep${person.toString()}_last_name`][0]
                : null
            }
            error={hasError(`rep${person.toString()}_last_name`)}
          />
        </Grid>

        {/* Email Address */}
        <Grid item xs={12} sm={12} md={12} lg={6} typography="formStyleLeft">
          <Typography variant="fieldTitle">
            Email Address{i === 0 ? "*" : null}
          </Typography>
          <CustomTextField
            name={`rep${person.toString()}_email_address`}
            placeholder="Email Address"
            value={
              formState?.values[`rep${person.toString()}_email_address`]
                ? formState?.values[`rep${person.toString()}_email_address`]
                : ""
            }
            variant="outlined"
            size="medium"
            id={`rep${person.toString()}_email_address`}
            fullWidth
            type="email"
            onChange={handleChange}
            helperText={
              hasError(`rep${person.toString()}_email_address`)
                ? formState.errors[`rep${person.toString()}_email_address`][0]
                : null
            }
            error={hasError(`rep${person.toString()}_email_address`)}
          />
        </Grid>

        {/* Current Role */}
        <Grid item xs={12} sm={12} md={12} lg={6} typography="formStyleRight">
          <Typography variant="fieldTitle">
            Current Role at the Organization{i === 0 ? "*" : null}
          </Typography>
          <CustomTextField
            name={`rep${person.toString()}_role`}
            value={
              formState?.values[`rep${person.toString()}_role`]
                ? formState?.values[`rep${person.toString()}_role`]
                : ""
            }
            placeholder="Role"
            variant="outlined"
            size="medium"
            id={`rep${person.toString()}_role`}
            fullWidth
            type="text"
            onChange={handleChange}
            helperText={
              hasError(`rep${person.toString()}_role`)
                ? formState.errors[`rep${person.toString()}_role`][0]
                : null
            }
            error={hasError(`rep${person.toString()}_role`)}
          />
        </Grid>
        {/* {i === repCount.length-1 ||  repCount.length===1?
        <Grid sx={{flexDirection:"row",display:"flex"}}>
        <CustomButton 
                sx={{
                  borderRadius:"180px",
                  maxWidth: "100px", 
                  width:"100px",
                  height:"100px",
                  maxHeight: "100px", 
                  margin:0,
                  display:repCount.length<3?"block" :"none"
                }} 
                onClick={()=>handleIncrease(repCount[0]+1)}
                
              >
                <Typography
            // variant="contactSubTitle"
            sx={{ fontSize:"20px" }}
          >
            +
          </Typography>
              </CustomButton>
       
              </Grid>
        :null} */}
      </Grid>
    ));
  };

  const hasError = (field) =>
    formState.errors[field] ? (formState.touched[field] ? true : false) : false;

  return (
    <>
      <ThemeProvider theme={theme}>
        <Grid container sx={{ color: "#000" }}>
          <Grid container direction="column">
            <Grid item sx={{ pb: "20px" }}>
              <Typography
                variant="h3"
                sx={{
                  fontFamily: "Changa",
                  fontWeight: 700,
                  "@media screen and (max-width: 900px)": {
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    padding: "20px 0 0 0",

                    // textAlign: "center",
                  },
                  position: "relative",
                }}
              >
                {"Nonprofit Registration"}
              </Typography>
              <Typography variant="subtitle1">
                Please complete this form to register your nonprofit or community-based organization. For this event, Clarintel will only accept nonprofits and community-based organizations currently registered with the IRS that serve underrepresented, marginalized, low income, and impoverished communities across the globe.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <form ref={donationForm} onSubmit={handleSubmit}>
          <Grid container direction="row">
            <Grid container direction="row">
              {/* First Name and Last Name */}
              <Grid
                container
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Grid
                  container
                  style={{ flexDirection: "row", alignItems: "center" }}
                >
                  <Grid
                  container
                  justifyContent="left"
                  id="section-2"
                  sx={{ flexDirection: "column" }}
                >
                  <Typography
                    variant="contactTitle"
                    sx={{
                      fontSize: {
                        xs: "1.3rem",
                        sm: "1.3rem",
                        md: "1.4rem",
                        lg: "1.7rem",
                      },
                      lineHeight: {
                        xs: "1.3rem",
                        sm: "1.3rem",
                        md: "1.4rem",
                        lg: "1.7rem",
                      },
                    }}
                  >
                    My Information
                  </Typography>
                  <Typography
                    variant="contactSubTitle"
                    sx={{ pt: "15px", lineHeight: "1.1rem" }}
                  >
                    Please provide some information about the organization that
                    will be in attendance.
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                  container
                  style={{ flexDirection: "row", alignItems: "center" }}
                >
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    typography="formStyleLeft"
                  >
                    <Typography variant="fieldTitle">First Name*</Typography>
                    <CustomTextField
                      name="registrant_first_name"
                      placeholder="First Name"
                      value={
                        formState?.values?.registrant_first_name ? formState.values.registrant_first_name : ""
                      }
                      variant="outlined"
                      size="medium"
                      id="registrant_first_name"
                      fullWidth
                      type="text"
                      onChange={handleChange}
                      helperText={
                        hasError("registrant_first_name")
                          ? formState.errors.registrant_first_name[0]
                          : null
                      }
                      error={hasError("registrant_first_name")}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    typography="formStyleRight"
                  >
                    <Typography variant="fieldTitle">Last Name*</Typography>
                    <CustomTextField
                      name="registrant_last_name"
                      placeholder="Last Name"
                      variant="outlined"
                      value={
                        formState?.values?.registrant_last_name ? formState.values.registrant_last_name : ""
                      }
                      size="medium"
                      id="registrant_last_name"
                      fullWidth
                      type="text"
                      onChange={handleChange}
                      helperText={
                        hasError("registrant_last_name")
                          ? formState.errors.registrant_last_name[0]
                          : null
                      }
                      error={hasError("registrant_last_name")}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  sx={{ flexDirection: "row", alignItems: "center",pb:"20px" }}
                >
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    typography="formStyleLeft"
                  >
                    <Typography variant="fieldTitle">Email Address*</Typography>
                    <CustomTextField
                      name="registrant_email_address"
                      value={
                        formState?.values?.registrant_email_address ? formState.values.registrant_email_address : ""
                      }
                      placeholder="Email Address"
                      variant="outlined"
                      size="medium"
                      id="email"
                      fullWidth
                      type="email"
                      onChange={handleChange}
                      helperText={
                        hasError("registrant_email_address")
                          ? formState.errors.registrant_email_address[0]
                          : null
                      }
                      error={hasError("registrant_email_address")}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    typography="formStyleRight"
                  >
                    <Typography variant="fieldTitle">Phone Number*</Typography>
                    <CustomTextField
                      name="registrant_phone_number"
                      value={
                        formState?.values?.registrant_phone_number ? formState.values.registrant_phone_number : ""
                      }
                      placeholder="(123) 345-6789"
                      variant="outlined"
                      size="medium"
                      id="registrant_phone_number"
                      fullWidth
                      InputProps={{
                        inputComponent: PhoneNumberPatternFormatCustom,
                      }}
                      type="tel"
                      onChange={handleChange}
                      helperText={
                        hasError("registrant_phone_number")
                          ? formState.errors.registrant_phone_number[0]
                          : null
                      }
                      error={hasError("registrant_phone_number")}
                    />
                  </Grid>
                </Grid>
                <Grid
              sx={{ width: "100%", display: "flex", justifyContent: "center" }}
            >
              <Grid
                container
                justifyContent="left"
                id="section-2"
                sx={{
                  flexDirection: "column",
                  background: "#dfdfdf",
                  width: "50%",
                  borderRadius: "30px",
                  height: "3px",
                  alignSelf: "center",
                  margin: "50px 0",
                }}
              />
            </Grid>
                <Grid
                  container
                  justifyContent="left"
                  id="section-2"
                  sx={{ flexDirection: "column" }}
                >
                  <Typography
                    variant="contactTitle"
                    sx={{
                      fontSize: {
                        xs: "1.3rem",
                        sm: "1.3rem",
                        md: "1.4rem",
                        lg: "1.7rem",
                      },
                      lineHeight: {
                        xs: "1.3rem",
                        sm: "1.3rem",
                        md: "1.4rem",
                        lg: "1.7rem",
                      },
                    }}
                  >
                    My Organization
                  </Typography>
                  <Typography
                    variant="contactSubTitle"
                    sx={{ pt: "15px", lineHeight: "1.1rem" }}
                  >
                    Please provide some information about the organization that
                    will be in attendance.
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                typography="formStyleLeft"
              >
                <Typography variant="fieldTitle">
                  Full Name of Your Organization*
                </Typography>
                <CustomTextField
                  name={"org_name"}
                  value={
                    formState?.values?.org_name ? formState.values.org_name : ""
                  }
                  placeholder="Organization Name"
                  variant="outlined"
                  size="medium"
                  id={"org_name"}
                  fullWidth
                  type="text"
                  onChange={handleChange}
                  helperText={
                    hasError("org_name") ? formState.errors["org_name"][0] : null
                  }
                  error={hasError("org_name")}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                typography="formStyleRight"
              >
                <Typography variant="fieldTitle">Organization EIN*</Typography>
                <CustomTextField
                  name={"org_ein"}
                  value={
                    formState?.values?.org_ein ? formState.values.org_ein : ""
                  }
                  placeholder="Enter your Organization's EIN"
                  variant="outlined"
                  size="medium"
                  id={"org_ein"}
                  fullWidth
                  InputProps={{
                    inputComponent:
                      EmployerIdentificationNumberPatternFormatCustom,
                  }}
                  type="text"
                  onChange={handleChange}
                  helperText={
                    hasError("org_ein") ? formState.errors["org_ein"][0] : null
                  }
                  error={hasError("org_ein")}
                />
              </Grid>
              {/* Email Address */}
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={6}
                typography="formStyleLeft"
              >
                <Typography variant="fieldTitle">
                  Organization Email Address*
                </Typography>
                <CustomTextField
                  name={"org_email_address"}
                  value={
                    formState?.values?.org_email_address
                      ? formState.values.org_email_address
                      : ""
                  }
                  placeholder="Organization Email Address"
                  variant="outlined"
                  size="medium"
                  id={"org_email_address"}
                  fullWidth
                  type="email"
                  onChange={handleChange}
                  helperText={
                    hasError("org_email_address")
                      ? formState.errors["org_email_address"][0]
                      : null
                  }
                  error={hasError("org_email_address")}
                />
              </Grid>
              {/* Phone Number */}
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={6}
                typography="formStyleRight"
              >
                <Typography variant="fieldTitle">
                  Organization Phone Number*
                </Typography>
                <CustomTextField
                  name={"org_phone_number"}
                  value={
                    formState?.values?.org_phone_number
                      ? formState.values.org_phone_number
                      : ""
                  }
                  placeholder="(123) 345-6789"
                  variant="outlined"
                  size="medium"
                  id={"phone"}
                  fullWidth
                  InputProps={{
                    inputComponent: PhoneNumberPatternFormatCustom,
                  }}
                  type="tel"
                  onChange={handleChange}
                  helperText={
                    hasError("org_phone_number")
                      ? formState.errors["org_phone_number"][0]
                      : null
                  }
                  error={hasError("org_phone_number")}
                />
              </Grid>
              {/*Headquarters */}
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={6}
                typography="formStyleLeft"
              >
                <Typography variant="fieldTitle">
                  Organization's Headquaters*
                </Typography>
                <CustomTextField
                  name={"headquarters"}
                  value={
                    formState?.values?.headquarters
                      ? formState.values.headquarters
                      : ""
                  }
                  placeholder="Organization Headquarters"
                  variant="outlined"
                  size="medium"
                  id={"headquarters"}
                  fullWidth
                  type="text"
                  onChange={handleChange}
                  helperText={
                    hasError("headquarters")
                      ? formState.errors["headquarters"][0]
                      : null
                  }
                  error={hasError("headquarters")}
                />
              </Grid>
              {/*Website */}
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={6}
                typography="formStyleRight"
              >
                <Typography variant="fieldTitle">
                  Organization's Website
                </Typography>
                <CustomTextField
                  name={"website"}
                  value={
                    formState?.values?.website ? formState.values.website : ""
                  }
                  placeholder="Organization Website"
                  variant="outlined"
                  size="medium"
                  id={"website"}
                  fullWidth
                  // type="url"
                  onChange={handleChange}
                  helperText={
                    hasError("website") ? formState.errors["website"][0] : null
                  }
                  error={hasError("website")}
                />
              </Grid>

              <Grid item xs={12} typography="formStyle" sx={{ pb: "1.14vw" }}>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="fieldTitle">
                    Organization's Mission & Purpose*
                  </Typography>
                  {/* <Typography variant="fieldTitle">
                    {formState.values.message.length}/{characterLimit}
                  </Typography> */}
                </Grid>
                <CustomMessageBox
                  name="mission"
                  placeholder="Mission & Purpose"
                  variant="outlined"
                  value={
                    formState?.values?.mission ? formState.values.mission : ""
                  }
                  multiline
                  rows={3}
                  id="mission"
                  fullWidth
                  type="text"
                  onChange={handleChange}
                  helperText={
                    hasError("mission") ? formState.errors.mission[0] : null
                  }
                  error={hasError("mission")}
                />
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={6}
                typography="formStyleLeft"
              >
                <Typography variant="fieldTitle">
                  Please list the social issues or causes that best connect to
                  the problems that your organization is working to solve. This
                  will be used to connect you to attendees at the event.
                </Typography>
                <CustomCreatableSelect
                  data={social_issues}
                  name={"social_issues"}
                  value={
                    formState?.values?.social_issues
                      ? formState.values.social_issues
                      : ""
                  }
                  question={"social_issues"}
                  answer={connectedSocialIssues}
                  setAnwser={setSocialIssues}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={6}
                typography="formStyleRight"
              >
                <Typography variant="fieldTitle">
                  What is biggest goal that your looking to achieve from
                  attending this event? This will be information will be used to
                  ensure that Clarintel meets your needs.
                </Typography>
                <CustomCreatableSelect
                  data={eventPurposeNonprofit}
                  question={"event_reasons"}
                  value={
                    formState?.values?.event_reasonss
                      ? formState.values.event_reasonss
                      : ""
                  }
                  name={"event_reasons"}
                  answer={event_reasons}
                  setAnwser={setevent_reasons}
                />
              </Grid>
            </Grid>

            <Grid
              sx={{ width: "100%", display: "flex", justifyContent: "center" }}
            >
              <Grid
                container
                justifyContent="left"
                id="section-2"
                sx={{
                  flexDirection: "column",
                  background: "#dfdfdf",
                  width: "50%",
                  borderRadius: "30px",
                  height: "3px",
                  alignSelf: "center",
                  margin: "50px 0",
                }}
              />
            </Grid>
            <Grid container direction="row">
              {/* First Name and Last Name */}
              <Grid
                container
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Grid
                  container
                  justifyContent="left"
                  id="section-2"
                  sx={{ flexDirection: "column" }}
                >
                  <Typography
                    variant="contactTitle"
                    sx={{
                      fontSize: {
                        xs: "1.3rem",
                        sm: "1.3rem",
                        md: "1.4rem",
                        lg: "1.7rem",
                      },
                      lineHeight: {
                        xs: "1.3rem",
                        sm: "1.3rem",
                        md: "1.4rem",
                        lg: "1.7rem",
                      },
                    }}
                  >
                    My Representatives
                  </Typography>
                  <Typography
                    variant="contactSubTitle"
                    sx={{ pt: "15px", lineHeight: "1.1rem", pb: "20px" }}
                  >
                    Please tell us about who will represent your organization at
                    the fair. Clarintel requires at least 1 representative for
                    each organization attending the fair.
                  </Typography>
                </Grid>

                {getReps()}
              </Grid>
            </Grid>

            {/* <Grid
              sx={{ width: "100%", display: "flex", justifyContent: "center" }}
            >
              <Grid
                container
                justifyContent="left"
                id="section-2"
                sx={{
                  flexDirection: "column",
                  background: "#dfdfdf",
                  width: "50%",
                  borderRadius: "30px",
                  height: "3px",
                  alignSelf: "center",
                  margin: "50px 0",
                }}
              />
            </Grid> */}

            {/* Message */}
            <Grid
              container
              style={{
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              {/* <Grid
                container
                justifyContent="left"
                id="section-2"
                sx={{ flexDirection: "column" }}
              >
                <Typography
                  variant="contactTitle"
                  sx={{
                    fontSize: {
                      xs: "1.3rem",
                      sm: "1.3rem",
                      md: "1.4rem",
                      lg: "1.7rem",
                    },
                    lineHeight: {
                      xs: "1.3rem",
                      sm: "1.3rem",
                      md: "1.4rem",
                      lg: "1.7rem",
                    },
                  }}
                >
                  Additional Information
                </Typography>
                <Typography
                  variant="contactSubTitle"
                  sx={{ pt: "15px", lineHeight: "1.1rem" }}
                >
                  Please share any additional information that you would like
                  Clarintel to know before the event.
                </Typography>
              </Grid>
              <Grid item xs={12} typography="formStyle" sx={{ pb: "1.14vw" }}>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="fieldTitle">
                    Messsage (1000 Character Limit)
                  </Typography>
                  <Typography variant="fieldTitle">
                    {formState.values.message.length}/{characterLimit}
                  </Typography>
                </Grid>
                <CustomMessageBox
                  name="message"
                  placeholder="Message"
                  variant="outlined"
                  multiline
                  rows={8}
                  value={
                    formState?.values?.message ? formState.values.message : ""
                  }
                  id="message"
                  fullWidth
                  type="text"
                  onChange={handleChange}
                  helperText={
                    hasError("message") ? formState.errors.message[0] : null
                  }
                  error={hasError("message")}
                />
              </Grid> */}

              {/* <Grid item xs={12} typography="formStyle">
                <Typography variant="fieldTitle">
                  Stay Up to Date with Clarintel
                </Typography>
                <FormGroup>
                  <CustomFormControlLabel
                    control={
                      <CustomCheckbox
                        name="opt_in"
                        onChange={handleChange}
                        checked={
                          formState?.values?.opt_in
                            ? formState.values.opt_in
                            : false
                        }
                      />
                    }
                    label="Send me the latest news from Clarintel"
                    helperText={
                      hasError("opt_in") ? formState.errors.opt_in[0] : null
                    }
                    error={hasError("opt_in")}
                  />
                </FormGroup>
              </Grid>

              <Grid item xs={12} typography="formStyle">
                <Typography variant="fieldTitle">Enhanced Exposure</Typography>
                <FormGroup>
                  <CustomFormControlLabel
                    control={
                      <CustomCheckbox
                        name="send_materials"
                        onChange={handleChange}
                        checked={
                          formState?.values?.send_materials
                            ? formState.values.send_materials
                            : false
                        }
                      />
                    }
                    label="Would you like to send Clarintel items to hand out to event attendees for enhanced exposure?"
                    helperText={
                      hasError("send_materials")
                        ? formState.errors.send_materials[0]
                        : null
                    }
                    error={hasError("send_materials")}
                  />
                </FormGroup>
              </Grid> */}
             
              {/* Donation Total */}
            </Grid>
            {/* Opt-ins */}

            {/* <Grid
              sx={{ width: "100%", display: "flex", justifyContent: "center" }}
            >
              <Grid
                container
                justifyContent="left"
                id="section-2"
                sx={{
                  flexDirection: "column",
                  background: "#dfdfdf",
                  width: "50%",
                  borderRadius: "30px",
                  height: "3px",
                  alignSelf: "center",
                  margin: "50px 0",
                }}
              />
            </Grid> */}
            <Grid
              container
              style={{
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              {/* Donation Total */}
            </Grid>
            {/* <Grid
              container
              style={{
                flexDirection: "row",
                alignItems: "center",
              }}
            >

              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
              >
                <Grid
                  item
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                  typography="formStyle"
                >
                  <Typography
                    sx={{
                      fontWeight: "900",
                      textAlign: "center",
                      fontFamily: "Changa",
                      fontSize: "18px",
                    }}
                  >
                    Your Donation*
                  </Typography>
                  <Typography
                    sx={{
                      color: "grey",
                      textAlign: "center",
                      fontFamily: "Changa",
                      fontSize: "14px",
                      paddingBottom: "30px",
                    }}
                  >
                    Clarintel currently uses Stripe to process donations. In
                    order to process your donation, Stripe charges us a 2.9%
                    processing fee including $0.30 for each donation we accept
                    through their service. Beneath your donation amount, please
                    view the actual amount you are donating given Stripes
                    service and processing fees. For your donation to be
                    processed successfully, please enter at least $1.00. For
                    your reference, a $10.61 total donation is the same as
                    donating $10.00.
                  </Typography>

                  <DonationTextField
                    name="donationAmount"
                    id="donationAmount"
                    onChange={handleChange}
                    sx={{ maxWidth: "500px" }}
                    InputProps={{
                      inputComponent: NumericFormatCustom,
                    }}
                    helperText={
                      hasError("donationAmount")
                        ? formState.errors.donationAmount[0]
                        : null
                    }
                    error={hasError("donationAmount")}
                  />
                </Grid>
              </Grid>
              <Typography
                sx={{
                  color: "grey",
                  width: "100%",
                  textAlign: "center",
                  fontFamily: "Changa",
                  fontSize: "14px",
                  display: formState.values.donationAmount ? "block" : "none",
                }}
              >
                {"Actual donation amount: $" +
                  (
                    formState.values.donationAmount -
                    formState.values.donationAmount * 0.029 -
                    0.3
                  ).toFixed(2)}
              </Typography>
            </Grid> */}
            {/* Submit Button */}
            <Grid
              item
              xs={12}
              typography="formStyle"
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <CustomSendDonationButton sx={{maxWidth:"300px",margin:0, minWidth:"auto"}}
                variant="contained"
                type="submit"
                disabled={!isFormComplete()}
              >
                Continue 
              </CustomSendDonationButton>
            </Grid>
          </Grid>
        </form>
      </ThemeProvider>
    </>
  );
}
let theme = createTheme();

theme = responsiveFontSizes(theme);

theme = responsiveFontSizes(theme);

theme.typography.contactTitle = {
  fontFamily: "Changa",
  fontSize: "2.7rem",
  lineHeight: "2.7rem",
  fontWeight: "900",
  color: "#000",
  // paddingTop: "40px",

  "@media screen and (max-width: 900px)": {
    fontSize: "1.8rem",
    lineHeight: "1.8rem",
    paddingTop: "25px",
  },
};

theme.typography.contactSubTitle = {
  color: "#000",
  fontFamily: "Changa",
  fontWeight: 500,
  fontSize: "1.3rem",
  lineHeight: "1.3rem",

  paddingBottom: "30px",
  "@media screen and (max-width: 900px)": {
    fontSize: "1.1rem",
    lineHeight: "1.1rem",
  },
};

theme.typography.fieldTitle = {
  color: "#000",
  fontFamily: "Changa",
  fontSize: "18px",
  fontWeight: "900",
  lineHeight: "18px",
};

theme.typography.h3 = {
  color: "#000",
  fontFamily: "Changa",
  fontSize: "2.2rem",
  lineHeight: "2.2rem",

  "@media screen and (max-width:900px)": {
    fontSize: "1.5rem !important",
    lineHeight: "1.5rem",
  },
};

theme.typography.formStyle = {
  // paddingLeft: "1.14vw",
  // paddingRight: "1.14vw",
  paddingTop: "1.14vw",
  "@media screen and (max-width: 900px)": {
    paddingTop: "3.14vw",
    paddingBottom: "3.14vw",
  },
};

theme.typography.formStyleLeft = {
  paddingRight: "1.14vw",
  paddingTop: "1.14vw",
  "@media screen and (max-width: 900px)": {
    paddingTop: "3.14vw",
    paddingBottom: "3.14vw",
  },
};

theme.typography.formStyleRight = {
  paddingLeft: "1.14vw",
  paddingTop: "1.14vw",
  "@media screen and (max-width: 900px)": {
    paddingTop: "3.14vw",
    paddingBottom: "3.14vw",
  },
};

theme.typography.topText = {
  fontFamily: "Changa",
  fontSize: "1.5rem",
  lineHeight: "1.5rem",
  textAlign: "center",
  flexGrow: 1,
  display: "flex",
  "@media screen and (max-width: 900px)": {
    fontSize: "1.3rem",
    lineHeight: "1.3rem",
  },
};

theme.typography.subtitle1 = {
  lineHeight: "1.5rem",
  fontSize: "1.3rem",
  fontWeight: 500,
  color: "#000",
  fontFamily: "Changa",
  alignSelf: "center",
  textAlign: "left",
  "@media (max-width: 900px)": {
    fontSize: "1.3rem",
    lineHeight: "1.3rem",
  },
};
