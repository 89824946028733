import { validate } from "validate.js";

export const schema = {
  registrant_first_name: {
    presence: { allowEmpty: false, message: "Please enter your first name." },
    length: {
      maximum: 128,
    },
  },
  registrant_last_name: {
    presence: { allowEmpty: false, message: "Please enter your last name." },
    length: {
      maximum: 128,
    },
  },
  registrant_email_address: {
    presence: { allowEmpty: false, message: "Please enter your email address." },
    email: true,
    length: {
      maximum: 300,
    },
  },
  registrant_phone_number: {
    presence: { allowEmpty: false, message: "Please enter your phone number." },
    format: {
      pattern: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
      message: function (
        value,
        attribute,
        validatorOptions,
        attributes,
        globalOptions,
      ) {
        return validate.format("^%{num} is not a valid phone number", {
          num: value.replace(/\D+/g, '')
     .replace(/(\d{3})(\d{3})?(\d{4})?/, '($1) $2-$3'),
        });
      },
    },
  },
  org_name: {
    presence: { allowEmpty: false, message: "Please enter the name of your organization." },
    length: {
      maximum: 300,
    },
  },
  org_ein: {
    presence: { allowEmpty: false, message: "Please enter your organization's EIN." ,},
    length: {
      maximum: 9,
    },
    
    format: {
      pattern: /([0-9]{2}-?[0-9]{7})$/,
      message: function (
        value,
        attribute,
        validatorOptions,
        attributes,
        globalOptions,
      ) {
        return validate.format("^%{num} is not a valid EIN.", {
          num: value.replace(/\D+/g, '')
     .replace(/(\d{2})(\d{7})?/, '$1-$2'),
        });
      },
    }, 
  },
  org_email_address: {
    presence: { allowEmpty: false, message: "Please enter your organization's email address." },
    email: true,
    length: {
      maximum: 300,
    },
  },
  org_phone_number: {
    presence: { allowEmpty: false, message: "Please enter your organization's phone number." },
    format: {
      pattern: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
      message: function (
        value,
        attribute,
        validatorOptions,
        attributes,
        globalOptions,
      ) {
        return validate.format("^%{num} is not a valid phone number", {
          num: value.replace(/\D+/g, '')
     .replace(/(\d{3})(\d{3})?(\d{4})?/, '($1) $2-$3'),
        });
      },
    },
  },
  headquarters: {
    presence: { allowEmpty: false, message: "Please enter your organization's headquarters." },
    length: {
      maximum: 500,
    },
  },
  website: {
    presence: false,
    format: {
      pattern: /^(((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+\.[a-z]+(\/[a-zA-Z0-9#]+\/?)*)*$/,
      message: function(value, attribute, validatorOptions, attributes, globalOptions) {
        return validate.format("^%{website} is not a valid website", {
          website: value
        });
      }
    }
  },
  mission: {
    presence: { allowEmpty: false, message: "Please enter your organization's mission." },
  },
  social_issues: {
    presence: false
  },
  event_reasonss: {
    presence: false
  },
  rep1_first_name: {
    presence: { allowEmpty: false, message: 'Please enter the first name of at least 1 representative that will be attending the event.' },
    length: {
      maximum: 128,
    },
  },
  rep1_last_name: {
    presence: { allowEmpty: false, message: 'Please enter the last name of at least 1 representative that will be attending the event.' },
    length: {
      maximum: 128,
    },
  },
  rep1_email_address: {
    presence: { allowEmpty: false, message: 'Please enter the email address of at least 1 representativethat will be attending the event.' },
    email: true,
    length: {
      maximum: 300,
    },
  },
  rep1_role: {
    presence: { allowEmpty: false, message: 'Please enter the job position of at least 1 representative that will be attending the event.' },
    
  },
  rep2_first_name: {
    presence: false

  },
  rep2_last_name: {
    presence: false

  },
  rep2_email_address: {
    presence: false,
    email: true,
    length: {
      maximum: 300,
    },
  },
  rep2_role: {
    presence: false
    
  },
  rep3_first_name: {
    presence: false

  },
  rep3_last_name: {
    presence: false

  },
  rep3_email_address: {
    presence: false,
    email: true,
    length: {
      maximum: 300,
    },
  },
  rep3_role: {
    presence: false
    
  },
  message: {
    presence: false,
    length: {
      maximum: 1000,
    },
  },
  agreement:{presence: { allowEmpty: false, message: 'is required' },},
  opt_in:{presence: false,},
  send_materials:{presence: false,},
  registration_amount: {
    presence: { allowEmpty: false, message: "is required" },
    numericality: {
      noStrings: true,
      greaterThan: 0.99,
      notLessThan: "must be greater than $0.99 USD",
    },
  },
};

