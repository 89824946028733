import { Grid, Typography } from "@mui/material";
import {
  ThemeProvider,
  createTheme,
  responsiveFontSizes,
} from "@mui/material/styles";
import { useStickyroll } from "@stickyroll/hooks";
import "animate.css/animate.min.css";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "react-slideshow-image/dist/styles.css";
import { PageHeader } from "../../components";
import { CustomSubmitButton } from "../../components/FormComponents/FormComponents";
import { care_fair_data } from "../../data/data_file";
import AttendeeRegisteration from '../../pages/AttendeeRegistrationPage/AttendeeRegistrationPage';
import NonprofitRegistrationPage from '../../pages/NonprofitRegistrationPage/NonprofitRegistrationPage';
import ImpactAreasSection from "../ProgramAreasSection/ImpactAreasSection";

// import DACHeader from "./DACHeader";
function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

export default function RegisterSection({ op_data }) {
  let theme = createTheme();
  const { state } = useLocation();
  const stateRegistrationType = JSON.parse(sessionStorage.getItem('registrationType'));

  const storedValue = JSON.parse(sessionStorage.getItem('nonprofit_registration'));
  const [registrationType, setRegistrationType] =
    useState(stateRegistrationType);
  // console.log(registrationType)

  const handleSetRegistrationType = (num) => {
    sessionStorage.setItem("registrationType", JSON.stringify(num));
    setRegistrationType(num)
    // setregistrationType(0):setregistrationType(1);
  };
  ///////////////////////////////////////////////////////
 

  const [currentAttendeeRegFilter, setCurrentAttendeeRegFilter] =
    useState(false);
  // const [completedAttendeeReg, setCompletedAttendeeReg] = useState((Number(Number(attendeeRegistrationStats[0].number_objective)/Number(attendeeRegistrationStats[1].number_objective))*100).toFixed(0));
  

  const [currentNonprofitRegFilter, setCurrentNonprofitRegFilter] =
    useState(false);
  // const [completedNonprofitReg, setCompletedNonprofitReg] = useState((Number(Number(nonprofitRegistrationStats[0].number_objective)/Number(nonprofitRegistrationStats[1].number_objective))*100).toFixed(0));

  theme = responsiveFontSizes(theme);

  theme.typography.h1 = {
    color: "#000",
    fontFamily: "Changa",
    fontSize: "1.7rem",
    lineHeight: "1.4rem",
    minWidth: "200px",
    textAlign: "left",
    "@media screen and (max-width:900px)": {
      fontSize: "1.2rem",
      lineHeight: "1.2rem",
    },
  };

  theme.typography.h2 = {
    color: "#000",
    fontFamily: "Changa",
    fontSize: "1.6rem",
    lineHeight: "1.6rem",
    minWidth: "200px",
    textAlign: "left",
    "@media screen and (max-width:900px)": {
      fontSize: "1.4rem",
      lineHeight: "1.4rem",
    },
  };

  theme.typography.h5 = {
    fontSize: "1.4rem",
    lineHeight: "1.4rem",
    color: "#000",
    fontWeight: "bolder",
    width: "100%",
    fontFamily: "Changa",
    overflowWrap: "break-word",
    margin: 0,

    "@media (max-width:900px)": {
      fontSize: "1rem",
      lineHeight: "1rem",
    },
  };
 
  theme.typography.h3 = {
    color: "#000",
    fontFamily: "Changa",
    fontSize: "2.2rem",
    lineHeight: "2.2rem",

    "@media screen and (max-width:900px)": {
      fontSize: "1.5rem !important",
      lineHeight: "1.5rem",
    },
  };
  theme.typography.h4 = {
    color: "#000",
    textAlign:"left",
    fontFamily: "Changa",
    fontSize: "5rem",
      lineHeight: "5rem",
    // textAlign:"center",
   
    "@media screen and (max-width: 900px)": {
      
      fontSize: "3.5rem",
    lineHeight: "3.5rem",
      textAlign:"left",
    },
    "@media screen and (max-width: 600px)": {
      fontSize: "3.3rem",
      lineHeight: "3.3rem",
      textAlign:"left",
    // "@media screen and (max-width:600px)": {
    //   fontSize: "2.5rem !important",
    //   lineHeight: "3.5 rem",
    // },
  },
};
  theme.typography.subtitle1 = {
    color: "#000",
    // textAlign:"center",
    fontFamily: "Changa",
    fontWeight:"700",
    fontSize: "1.5rem",
      lineHeight: "1.3rem",
    
    padding:"5px 0 0",
    "@media screen and (max-width: 900px)": {
      fontSize: "1.3rem",
    lineHeight: "1.1rem",
      textAlign:"left",
    },
  
    "@media screen and (max-width: 600px)": {
      fontSize: "1.1rem",
      lineHeight: "1rem",
      textAlign:"left",
    // "@media screen and (max-width:600px)": {
    //   fontSize: "2.5rem !important",
    //   lineHeight: "3.5 rem",
    // },
  },
  };


  theme.typography.subtitle2 = {
    color: "rgb(98, 182, 208)",
    fontFamily: "Changa",
    fontWeight:"700",
    fontSize: "1.5rem",
    lineHeight: "1.5rem",
    padding:"15px 0 0",
    "@media screen and (min-width: 900px)": {
      fontSize: "1.7rem",
      lineHeight: "1.7rem",
      textAlign:"left",
    },
  };

  var statTheme = {
    width: "50vw",
    "@media screen and (max-width: 900px)": {
      // maxWidth: "400px",
      width: "100%",
    },
  };
  var statTheme2 = {
    width: "50vw",
    "@media screen and (max-width: 900px)": {
      width: "100%",
    },
  };

  const headlines = [
    "Digital Acts Of Care",
    "Connected & DAC Posts",
    "Instant Action Button",
  ];

  const [wrapper] = useStickyroll({
    pages: headlines,
  });

  const method =
    "Our programs are developed with 1 thought in mind... drive revolutionary change within philanthropy and social impact: data and relationships. Through our unique framework, Phaction creates the environment where unbreakable bonds are formed using credible information, data, and truth that deepens societal understanding, increases knowledgeable philanthropic engagement, enhances social unification, and ultimately leads to human equity.";
  const method2 =
    "After taking action you can trust with credible nonprofits,  Phaction provides everything you need to track and share the positive impact made by your actions in real time.";

  const opData = [
    {
      title: "Attendee Registration",
      text: "Please select who this registration is for.",
      link: "section-3",
      key: 2,
      details: ["Details 1", "Details 2", "Details 3"],
    },
  ];

  function OpTextItem({ item }) {
    return (
      <Grid style={{ display: "flex", flexDirection: "column" }}>
        <Grid item xs={12} sm={12} md={12} lg={12} style={{ justifyContent: "center" , width:"100%", padding: "100px 0 3.14vw"}} sx={statTheme}>
        <Grid 
              container 
              direction="row" 
              sx={{ 
                "@media screen and (max-width: 900px)": {
                  // paddingBottom: "0",
                },
                paddingBottom: "3.14vw", 
              }}
            >
              <Grid item xs={12} sx={{textAlign:"left",display:"flex", flexDirection:"row","@media screen and (max-width: 900px)": {
                      // textAlign:"center",
                      alignItems:"center",
                      flexDirection:"column",
                    }}}>
              <Typography
                  // variant="h2" 
                  component={'span'}

                  sx={{
                    margin: 0,
                    // width:"100%",
                    flexShrink:0,
                    // display: "flex",
                    fontFamily: "Changa",
                    color: "#62B6D0",
                    fontSize:"1.25rem",
                    lineHeight:"1.6rem",
                    "@media screen and (max-width: 900px)": {
                      // textAlign: "center",
                      width:"100%",
                    },
                  }}
                >
                  {/* The Clarintel Apprenticeship Rotational Emergence Program */}
                  Clarintel Presents
                  </Typography>

                  <Typography
                    variant="h6"
                    component={'span'}
                    sx={{
                      letterSpacing: "-0.09em",
                      fontFamily: "Changa",
                      paddingLeft: "5px",
                      lineHeight:"1.6rem",
                      color: "#62B6D0",
                      "@media screen and (max-width: 900px)": {
                        display:"none",
                      },
                    }}
                  >
                    {`----`}
                  </Typography>
                </Grid>
            </Grid>
            <Grid sx={{display:"flex"}}>
            <Grid sx={{display:"flex",flexDirection:"column",padding:"",maxWidth:"80%", "@media screen and (max-width: 900px)": {
                        maxWidth:"100%"
                      },}}>
        <Typography
          variant="h4"
        >
          {"The Clarintel Care Fair"}
        </Typography>
        <Typography
          variant="subtitle2"
        >
          {"The Adele H. Stamp Student Union"}
        </Typography>
        
        <Typography
          variant="subtitle1"
        >
          {"3972 Campus Drive College Park, MD 20742"}
        </Typography>
        <Typography
          variant="subtitle1"
        >
          {"Thursday, April 4, 2024"}
        </Typography>
        <Typography
          variant="subtitle1"
        >
          {"12:00pm EST - 8:00pm EST"}
        </Typography>
</Grid>
        <Grid sx={{position:"absolute", paddingBottom:"40px",height:"100%",maxHeight: "20vh",zIndex:10, right:"10%","@media screen and (max-width: 900px)": {
                            display:"flex",top:"380px",right:"7%",maxHeight: "22vh"
                          },"@media screen and (max-width: 600px)": {
                           maxWidth:"100px",paddingBottom:"0px",height:"auto",
                            right:"7%",
                            top:"313px",
                          },}}> 
                  <img
                    src="/images/register-hearts.png"
                    style={{
                      width: "100%",
                      
                      height:"100%",
                    }}
                    alt={"background"}
                    loading="lazy"
                  />
                  </Grid>
      </Grid>
      </Grid>
      <Grid
            sx={{
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
              paddingBottom: "3.14vw",
              zIndex: 10,
              "@media screen and (max-width: 900px)": {
                width: "100%",
              },
            }}
          >
            <Typography variant="subtitle1" sx ={{fontWeight:"300"}}>
               {care_fair_data.about_the_event}
              </Typography>
          </Grid>
        <Grid
          id={item.link}
          sx={{
            // paddingBottom: item.key !== opData.length - 1 ? "150px" : 0,
            "@media screen and (max-width: 900px)": {
              alignItems: "center",
              flexDirection: "column",
              paddingBottom: 0,
            },
            width: "100%",
            flexDirection: "row",
            display: "flex",
          }}
        >
         
          
          <Grid
            style={{ display: "flex",width:"100%" }}
          >
            <Grid
              sx={{
                width:"100%",
                // marginLeft: "3.14vw",
                "@media screen and (max-width: 900px)": {
                  flexDirection: "column",
                  display: "flex",
                  // margin:"7.14vw 3.14vw",
                  textAlign: "center",
                  padding:"20px 0 0 0",
                },
                justifyContent: "center",
                flexDirection: "column",
                display: "flex",
              }}
            >
              <Typography variant="h2">{item.text}</Typography>

              <Grid
                sx={{
                  width: "100%",
                  minHeight: "80px",
                  justifyContent: "space-around",
                  display: "flex",
                  flexDirection: "column",
                  alignSelf: "center",
                 
                  "@media screen and (max-width: 900px)": {
                    marginLeft: "0",
                    marginTop: "10px",
                    // alignItems: "center",
                    // minHeight: "100px",
                    // height: "150px",
                    justifyContent: "space-evenly",
                  },
                }}
              >
                 <Grid
                      container
                      direction="row"
                      sx={{
                        paddingTop:"20px",
                        display: "flex",

                        justifyContent: "flex-start",
                        "@media screen and (max-width: 900px)": {
                          // alignItems: "center",
                          justifyContent: "center",
                          flexDirection: "column",
                        },
                      }}
                    >
                      <Grid
                  item
                  // xs={6}
                  typography="formStyle"
                  sx={{
                    display: "flex",
                    // justifyContent: "fle",
                    paddingRight:"20px",
                    margin:"10px 0",
                    "@media screen and (max-width: 900px)": {
                      // alignItems: "center",
                      padding:0,
                      // flexDirection: "column",
                    },
                  }}
                >
                  <CustomSubmitButton 
                  onClick={() =>
                       handleSetRegistrationType(1)
                  }
                  sx={{ "@media screen and (max-width: 900px)": {
                    // alignItems: "center",
                    width:"100%", margin:0,
                    // flexDirection: "column",
                  }, width:"300px", margin:0, cursor:registrationType?"default":"pointer",  backgroundColor: registrationType
                  ? "#F1B9D0"
                  : "#FFF",color: !registrationType ? "#F1B9D0": "#FFFF",transition: "all ease 1s",
                  "&:hover": {
                    transition: "all ease 1s",transform:!registrationType ?"scale(1.15)":null,
                    backgroundColor: !registrationType ?  "#FFFF": "#F1B9D0",
                    color: !registrationType ? "#F1B9D0": "#FFFF",
                  }, }}
                    variant="contained"
                    type="button"
                    // disabled={!isFormComplete()}
                  >
                    Register Myself
                  </CustomSubmitButton>
                </Grid>
                {/* <Grid
                  item
                  // xs={12}
                  typography="formStyle"
                  sx={{
                    display: "flex",
                    margin:"10px 0",
                    // justifyContent: "center",
                    paddingLeft:"20px",
                    "@media screen and (max-width: 900px)": {
                      // alignItems: "center",
                      padding:0, width:"100%"
                      // flexDirection: "column",
                    },
                  }}
                >
                  <CustomSubmitButton 
                   onClick={() =>
                       handleSetRegistrationType(0)
                      
                  }
                  sx={{width:"300px","@media screen and (max-width: 900px)": {
                    // alignItems: "center",
                    width:"100%", margin:0,
                    // flexDirection: "column",
                  }, margin:0, cursor:!registrationType?"default":"pointer", backgroundColor: !registrationType
                  ? "#F1B9D0"
                  : "#FFF",color: registrationType ? "#F1B9D0": "#FFFF",transition: "all ease 1s",
                  "&:hover": {
                    transition: "all ease 1s",transform:registrationType ?"scale(1.15)":null,
                    backgroundColor: registrationType ?  "#FFFF": "#F1B9D0",
                    color: registrationType ? "#F1B9D0": "#FFFF",
                  }, }}
                    variant="contained"
                    type="button"
                    // disabled={!isFormComplete()}
                  >
                    Register My Org
                  </CustomSubmitButton>
                </Grid> */}
                      
                    </Grid>
                {
                  // item.key===2?
                  <>
                    {/* <Grid
                      container
                      direction="row"
                      sx={{
                        justifyContent: "space-between",
                        "@media screen and (max-width: 900px)": {
                          // alignItems: "center",
                          display: "flex",
                          justifyContent: "space-between",
                          // flexDirection: "column",
                        },
                      }}
                    >
                      <Grid
                        container
                        // direction="row"
                        sx={{
                          display: "flex",
                          // alignItems: "center",
                          width: "auto",
                          padding:"20px 10px 0 0",
                        }}
                      >
                        <Typography
                          color="#000"
                          gutterBottom
                          variant="h1"
                          style={{
                            
                            paddingRight: 10,
                            margin: 0,
                            width: "auto",
                          }}
                        >
                          {"For Myself"}
                        </Typography>
                        <Button
                          onClick={() =>
                            registrationType === false
                              ? handleSetRegistrationType()
                              : null
                          }
                          style={{
                            minWidth: "auto",
                            background: registrationType
                              ? "#F1B9D0"
                              : "#FFF",
                            borderColor: "#F1B9D0",
                            borderWidth: "2px",
                            borderStyle: "solid",
                            borderRadius: 50,
                            height: 20,
                            width: 20,
                          }}
                        />
                      </Grid>
                      <Grid
                        container
                        // direction="row"
                        sx={{
                          display: "flex",
                          // alignItems: "center",
                          width: "auto",
                          padding:"20px 0 0 0",
                        }}
                      >
                        <Typography
                          color="#000"
                          gutterBottom
                          variant="h1"
                          style={{
                            
                            paddingRight: 10,
                            margin: 0,
                            width: "auto",
                          }}
                        >
                          {"For a Nonprofit"}
                        </Typography>
                        <Button
                          onClick={() =>
                            !registrationType === false
                              ? handleSetRegistrationType()
                              : null
                          }
                          style={{
                            minWidth: "auto",
                            background: !registrationType
                              ? "#F1B9D0"
                              : "#FFF",
                            borderColor: "#F1B9D0",
                            borderWidth: "2px",
                            borderStyle: "solid",
                            borderRadius: 50,
                            height: 20,
                            width: 20,
                          }}
                        />
                      </Grid>
                    </Grid>
                     */}
                  </>
                }
              </Grid>
             
            </Grid>
          </Grid>
          
        </Grid>
        
      </Grid>
    );
  }

  function getOpTextList(items) {
    return (
      <Grid
        ref={wrapper}
        style={{ width: "100%", flexDirection: "column", display: "flex" }}
      >
        {items.map((item) =>
          item.key === 9 ? (
            <Grid style={{ width: "100%" }}>
              <OpTextItem key={item.key} item={item} />
              <ImpactAreasSection />
            </Grid>
          ) : (
            <OpTextItem key={item.key} item={item} />
          )
        )}
      </Grid>
    );
  }

  return (
    <>
      <PageHeader
        title="Register Today"
        background={"rgb(98, 182, 208)"}
        isContact={1}
      />
      <Grid
        container
        sx={{
          marginTop: "50px",
          background: "#FFFF",
          color: "white",
          padding: "0px 7.14vw",
          display: "flex",
          flexWrap: "wrap",
          "@media screen and (max-width: 900px)": {
            padding: "0px 3.14vw",
          },
        }}
      >
        <ThemeProvider theme={theme}>
          

          {getOpTextList(opData)}
        {registrationType?
        <AttendeeRegisteration op_data={op_data}/>
      :
      <NonprofitRegistrationPage/>
      }
              </ThemeProvider>

      </Grid>
      
    </>
  );
}
