import {
  Box,
  CssBaseline,
  FormGroup,
  Grid,
  Typography
} from "@mui/material";
import {
  ThemeProvider,
  createTheme,
  responsiveFontSizes,
} from "@mui/material/styles";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import AsyncCreatableSelect from "react-select/async-creatable";
import validate from "validate.js";
import {
  CustomCheckbox,
  CustomFormControlLabel,
  CustomSubmitButton,
  CustomTextField
} from "../../components/FormComponents/FormComponents";
import { PhoneNumberPatternFormatCustom } from "../../components/InputComponents/InputComponents";
import { social_issues } from "../CKSurvey/social_issues";
import { schema } from "./attendeeSchema";

export default function AttendeeRegistration({ page_title, op_data }) {
  const formValues = {};
  const attendeeRegistration = useRef();
  const navigate = useNavigate();
  const [answer5, setAnswer5] = useState([]);

  const storedValue = JSON.parse(
    sessionStorage.getItem("attendee_registration")
  );
  const [formState, setFormState] = useState(
    storedValue
      ? storedValue
      : {
          isValid: false,
          values: formValues,
          touched: {},
          errors: {},
        }
  );
  // console.log(storedValue,formState)

  const characterLimit = 1000;
  const isFormComplete = () => {
    return (
      Object.keys(formState?.values).length > 0 &&
      formState.values?.firstName?.length > 0 &&
      formState.values?.lastName?.length > 0 &&
      formState.values?.phoneNumber?.toString().length > 0 &&
      formState.values?.emailAddress?.length > 0 &&
      formState.isValid
    );
  };
  useEffect(() => {
    const errors = validate(formState.values, schema);
    // document.title = page_title;

    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  const submitEventRegistration = async (e) => {
    e.preventDefault();
    sessionStorage.setItem("attendee_registration", JSON.stringify(formState));
    navigate("/care/register/terms", { state: {...formState.values} });
  };

  const handleChange = (e) => {
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [e.target.name]:
          e.target.type === "checkbox" ? e.target.checked : e.target.value,
      },
      touched: {
        ...formState.touched,
        [e.target.name]: true,
      },
    }));
  };
  const handleSetValue = (value, setValue, id, type) => {
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [id]:
          type === "checkbubble"
            ? value
            : Object.values(value.map((i) => i.label)),
      },
      touched: {
        ...formState.touched,
        [id]: true,
      },
    }));
    setValue(value);
  };
  const CustomCreatableSelect = ({ data, question, answer, setAnwser }) => {
    const filterColors = (inputValue) => {
      return data.filter((i) =>
        i.label.toLowerCase().includes(inputValue.toLowerCase())
      );
    };

    const promiseOptions = (inputValue) =>
      new Promise((resolve) => {
        setTimeout(() => {
          resolve(filterColors(inputValue));
        }, 500);
      });

    return (
      <AsyncCreatableSelect
        isMulti
        value={answer}
        cacheOptions
        name={question}
        defaultOptions={data.slice(0, 100)}
        loadOptions={promiseOptions}
        onChange={(newValue) => handleSetValue(newValue, setAnwser, question, 'arraylist')}
        menuPortalTarget={document.body}
        styles={{
          container: (base) => ({
            justifyContent: "center",
            alignItems: "center",
            paddingTop: "10px",
          }),
          control: (base, state) => ({
            ...base,
            borderRadius: "30px",
            fontFamily: "Changa",
            minHeight: "70px",
            borderColor: state.isFocused ? "#62B6D0" : "lightgrey",
            boxShadow: state.isFocused ? "0 0 0 1px #62B6D0" : 0,
            "&:hover": { borderColor: "#62B6D0" },
          }),
          menu: (base) => ({
            ...base,
            marginTop: "10px",
            zIndex: 9999,
            fontFamily: "Changa",
            birderRadius: "20px",
          }),
          option: (base, state) => ({
            ...base,
            fontFamily: "Changa",
            backgroundColor: state.isFocused ? "#62B6D0" : "#FFF",
            "&:hover": { backgroundColor: "#62B6D0" },
          }),
          multiValue: (styles, { data }) => {
            // const color = ;
            return {
              ...styles,
              backgroundColor: "#62B6D0",
              borderRadius: "40px",
            };
          },
          multiValueLabel: (styles, { data }) => ({
            ...styles,
            color: "#FFF",
          }),
          multiValueRemove: (styles, { data }) => ({
            ...styles,
            color: "rgba(255,255,255,.7)",
            ":hover": {
              backgroundColor: "transparent",
              color: "white",
            },
          }),
        }}
        options={data}
      />
    );
  };

  const hasError = (field) =>
    formState.errors[field] ? (formState.touched[field] ? true : false) : false;
    
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <main>
        {/* <PageHeader
          title="Register Today"
          background={"rgb(98, 182, 208)"}
          isContact={1}
        /> */}
        <Box
          sx={{
            pb: 8,
            backgroundColor: "white",
            color: "#000",
          }}
        >
         
          <Grid
            style={{
              // paddingLeft: "3.14vw",
              // paddingRight: "3.14vw",
              paddingTop: "3.14vw",
            }}
          >
           <Typography
          variant="h3"
          sx={{
            fontFamily: "Changa",
              fontWeight:700,
            "@media screen and (max-width: 900px)": {
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              padding: "20px 0 0 0",
              
              // textAlign: "center",
            },
            position:
             "relative"
                
          }}
        >
          {"Attendee Registration"}
          
        </Typography>
        <Typography variant="subtitle1">
        Please complete this form to register yourself or another person. This event is FREE AND OPEN to all people!
              </Typography>
            {/* <Grid container direction="column">
              <Grid item>
                <Typography variant="questionTitle">
                Register to the Event of the Future
                </Typography>
              </Grid>
              <Grid item sx={{ pt: "15px", lineHeight: "1.1rem" }}>
                <Typography variant="questionText">
                  Thank you for your interest in Clarintel, the future of
                  philanthropy and positive impact.
                  <br />
                  <br />
                  If you have any questions and comments, or if you would like
                  to share your ideas, interest, and support, feel free to
                  contact us using the form below. We'd love to connect with you
                  and hear your thoughts.
                  <br />
                  <br />
                  To better serve you and provide you with the information you
                  need, please provide as much detail as you can regarding your
                  inquiry, and we will get back to you as soon as we can.
                  <br />
                  <br />
                  We can't wait to meet you!
                  <br />
                  <br />
                  Thank you.
                </Typography>
              </Grid>
            </Grid> */}
            <form
              ref={attendeeRegistration}
              headers="application/json"
              name="contact-form"
              onSubmit={submitEventRegistration}
            >
              <Grid container direction="row">
                {/* Header */}
                {/* <Grid container justifyContent="left" xs={12}>
                  <Typography variant="contactTitle">
                   Come to the Event of the Future
                  </Typography>
                  <Typography variant="contactSubTitle" sx={{ pt: "15px" }}>
                    To ensure that you receive a quick and accurate response to
                    your inquiry, please select a topic from the dropdown list
                    below. We will respond to you shortly.
                  </Typography>
                </Grid> */}

                {/* Name and Email Address */}
                <Grid
                  container
                  style={{ flexDirection: "row", alignItems: "center" }}
                >
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    typography="formStyleLeft"
                  >
                    <Typography variant="fieldTitle">First Name*</Typography>
                    <CustomTextField
                      name="firstName"
                      placeholder="First Name"
                      variant="outlined"
                      size="medium"
                      id="firstName"
                      value={formState?.values?.firstName ? formState.values.firstName : ""}
                      fullWidth
                      type="text"
                      onChange={handleChange}
                      helpertext={
                        hasError("firstName")
                          ? formState.errors.firstName[0]
                          : null
                      }
                      error={hasError("firstName")}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    typography="formStyleRight"
                  >
                    <Typography variant="fieldTitle">Last Name*</Typography>
                    <CustomTextField
                      name="lastName"
                      placeholder="Last Name"
                      variant="outlined"
                      size="medium"
                      value={formState?.values?.lastName ? formState.values.lastName : ""}
                      id="lastName"
                      fullWidth
                      type="text"
                      onChange={handleChange}
                      helpertext={
                        hasError("lastName")
                          ? formState.errors.lastName[0]
                          : null
                      }
                      error={hasError("lastName")}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  style={{ flexDirection: "row", alignItems: "center" }}
                >
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    typography="formStyleLeft"
                  >
                    <Typography variant="fieldTitle">Email Address*</Typography>
                    <CustomTextField
                      name="emailAddress"
                      placeholder="Email Address"
                      variant="outlined"
                      size="medium"
                      id="email"
                      fullWidth
                      type="email"
                      value={formState?.values?.emailAddress ? formState.values.emailAddress : ""}
                      onChange={handleChange}
                      helpertext={
                        hasError("emailAddress")
                          ? formState.errors.emailAddress[0]
                          : null
                      }
                      error={hasError("emailAddress")}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    typography="formStyleRight"
                  >
                    <Typography variant="fieldTitle">Phone Number*</Typography>
                    <CustomTextField
                      name="phoneNumber"
                      placeholder="(123) 345-6789"
                      variant="outlined"
                      value={formState?.values?.phoneNumber ? formState.values.phoneNumber : ""}
                      size="medium"
                      id="phone"
                      fullWidth
                      InputProps={{
                        inputComponent: PhoneNumberPatternFormatCustom,
                      }}
                      type="tel"
                      onChange={handleChange}
                      helpertext={
                        hasError("phoneNumber")
                          ? formState.errors.phoneNumber[0]
                          : null
                      }
                      error={hasError("phoneNumber")}
                    />
                  </Grid>
                </Grid>

          
                {/* Name and Email Address */}

                <Grid item xs={12} sm={12} typography="formStyle">
                  <Typography variant="fieldTitle">
                    What are a few social issues that you would like to see
                    nonprofits at the event working to solve? This will help us
                    connect you with the nonprofits that align with your
                    interests. You can search for and choose an answer from the
                    list below or type in and select the option to create your
                    answer if it is not included in the list.{" "}
                  </Typography>
                  {/* <Typography variant="fieldTitle" style={{paddingTop:"10px",width:"100%",display:"flex"}}>
                      Please search for and choose an answer from the list below or type in and select the option to create your answer if it is not included in the list. 
                      </Typography> */}
                  <CustomCreatableSelect
                    data={social_issues}
                    question={"event_reasons"}
                    name={"event_reasons"}
                    value={
                      formState?.values?.event_reasons
                        ? formState.values.event_reasons
                        : ""
                    }
                    answer={answer5}
                    setAnwser={setAnswer5}
                  />
                </Grid>
                {/* <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  typography="formStyle"
                >
                  <Typography variant="fieldTitle">
                    What are you're looking forward to at this event? This will
                    be information will be used to ensure that Clarintel meets
                    your needs.
                  </Typography>
                  <CustomCreatableSelect
                    data={eventPurposeAttendee}
                    question={"event_reasonss"}
                    answer={event_reasons}
                    setAnwser={setevent_reasons}
                  />
                </Grid> */}
                {/* Message */}
                {/* <Grid item xs={12} typography="formStyle" sx={{ pb: "1.14vw" }}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography variant="fieldTitle">
                      Messsage (1000 Character Limit)
                    </Typography>
                    <Typography variant="fieldTitle">
                      {formState.values.message.length}/{characterLimit}
                    </Typography>
                  </Grid>
                  <CustomMessageBox
                    name="message"
                    placeholder="Message"
                    variant="outlined"
                    multiline
                    rows={5}
                    id="message"
                    fullWidth
                    type="text"
                    onChange={handleChange}
                    helpertext={
                      hasError("message") ? formState.errors.message[0] : null
                    }
                    error={hasError("message")}
                  />
                </Grid> */}

                {/* Opt-ins */}
                <Grid item xs={12} typography="formStyle" >
                  <Typography variant="fieldTitle" >
                    Help Your Community Leaders
                  </Typography>
                  <FormGroup>
                    <CustomFormControlLabel sx={{pt:"10px"}}
                      control={
                        <CustomCheckbox name="share_info" onChange={handleChange} checked={formState?.values?.share_info? formState.values.share_info:false} />
                      }
                      name="share_info"
                      label="Would you like to share your information with the nonprofits that match your interests?"
                      helpertext={
                        hasError("share_info") ? formState.errors.share_info[0] : null
                      }
                      
                      error={hasError("share_info").toString()}
                    />
                  </FormGroup>
                </Grid>

                {/* Submit Button */}
                <Grid
                  item
                  xs={12}
                  typography="formStyle"
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <CustomSubmitButton sx={{width:"100%",margin:0}}
                    variant="contained"
                    type="submit"
                    disabled={!isFormComplete()}
                  >
                    Continue
                  </CustomSubmitButton>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Box>
      </main>
    </ThemeProvider>
  );
}

let theme = createTheme();

theme = responsiveFontSizes(theme);

theme.typography.contactTitle = {
  fontFamily: "Changa",
  fontSize: "2rem",
  lineHeight: "2rem",
  fontWeight: "900",
  paddingTop: "40px",

  "@media screen and (max-width: 900px)": {
    fontSize: "1.3rem",
    lineHeight: "1.3rem",
    paddingTop: "25px",
  },
};

theme.typography.subtitle1 = {
  lineHeight: "1.5rem",
  fontSize: "1.3rem",
  fontWeight: 500,
  color: "#000",
  fontFamily: "Changa",
  alignSelf: "center",
  textAlign: "left",
  "@media (max-width: 900px)": {
    fontSize: "1rem",
    lineHeight: "1rem",
  },
  "@media screen and (max-width: 790px)": {
    marginLeft: "0",
    marginTop: "16px",
  },
};

theme.typography.contactSubTitle = {
  color: "#000",
  fontFamily: "Changa",
  fontSize: "1.3rem",
  lineHeight: "1.3rem",

  paddingBottom: "30px",
  "@media screen and (max-width: 900px)": {
    fontSize: "1.1rem",
    lineHeight: "1.1rem",
  },
};

theme.typography.fieldTitle = {
  color: "#000",
  fontFamily: "Changa",
  fontSize: "18px",
  fontWeight: "900",
  lineHeight: "18px",
};

theme.typography.formStyleLeft = {
  // paddingLeft:"1.14vw",
  paddingRight: "1.14vw",
  paddingTop: "1.14vw",
  "@media screen and (max-width: 900px)": {
    paddingTop: "3.14vw",
    paddingBottom: "3.14vw",
  },
};

theme.typography.h3 = {
  color: "#000",
  fontFamily: "Changa",
  fontSize: "2.2rem",
  lineHeight: "2.2rem",

  "@media screen and (max-width:900px)": {
    fontSize: "1.5rem !important",
    lineHeight: "1.5rem",
  },
};

theme.typography.formStyleRight = {
  paddingLeft: "1.14vw",
  // paddingRight:"1.14vw",
  paddingTop: "1.14vw",
  "@media screen and (max-width: 900px)": {
    paddingTop: "3.14vw",
    paddingBottom: "3.14vw",
  },
};

theme.typography.formStyle = {
  // paddingLeft:"1.14vw",
  // paddingRight:"1.14vw",
  paddingTop: "1.14vw",
  "@media screen and (max-width: 900px)": {
    paddingTop: "3.14vw",
    paddingBottom: "3.14vw",
  },
};

theme.typography.smallText = {
  color: "#000",
  fontFamily: "Changa",
  fontSize: "20px",
  lineHeight: "20px",
  "@media screen and (max-width: 900px)": {
    fontSize: "18px",
    lineHeight: "18px",
  },
};

theme.typography.questionTitle = {
  fontFamily: "Changa",
  fontSize: "2rem",
  lineHeight: "2rem",
  fontWeight: "900",
  "@media screen and (max-width: 900px)": {
    fontSize: "1.3rem",
    lineHeight: "1.3rem",
  },
};

theme.typography.questionText = {
  fontFamily: "Changa",
  fontSize: "1.3rem",
  lineHeight: "1rem",
  "@media screen and (max-width: 900px)": {
    fontSize: "1.1rem",
    lineHeight: "1.1rem",
  },
};
